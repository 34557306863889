@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;700');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700');

.Tiptap_hidden__FNfSk {
    display: none;
}

.Tiptap_ProseMirror__svmPJ {
    font-family: 'Source Serif Pro', sans-serif;
    font-size: 20px;
}

.Tiptap_ProseMirror__svmPJ h1 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 700;
}

.Tiptap_ProseMirror__svmPJ h2 {
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-weight: 600;
}

.Tiptap_ProseMirror__svmPJ h3 {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.Tiptap_ProseMirror__svmPJ h4 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.Tiptap_ProseMirror__svmPJ h5 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.Tiptap_ProseMirror__svmPJ h6 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.Tiptap_editorButton__HE1cA {
    font-size: 14px;
    font-family: Inter, sans-serif;
    background-color: #1f2937;
    padding: 10px 25px;
    border-radius: 6px;
    text-decoration: none;
    margin-top: 5px;
    margin-bottom: 5px;
}

.Tiptap_editorSubscribeButton__u_Wsg {
    font-family: Inter, sans-serif;
    color: #ffffff !important;
    background-color: #1f2937;
    font-weight: bold;
    border-radius: 6px;
}

.MenuBar_menuBar__tybhC {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(13, 13, 13, 0.1);
    position: relative;
}

.MenuBar_menuBar__tybhC button {
    color: rgb(70, 70, 70);
    padding: 0.3rem 0.36rem;
    margin: 0.1rem;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

@media (min-width: 768px) {
    .MenuBar_menuBar__tybhC button {
        padding: 0.3rem 0.42rem;
    }
}

.MenuBar_menuBar__tybhC button:hover {
    background-color: #dadada;
    color: rgb(70, 70, 70);
}

.MenuBar_menuBar__tybhC .MenuBar_divider__7qtkI {
    margin: 0.2rem;
    border-left: 1px solid rgba(13, 13, 13, 0.1);
    align-self: stretch;
}

