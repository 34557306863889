.menuBar {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(13, 13, 13, 0.1);
    position: relative;
}

.menuBar button {
    color: rgb(70, 70, 70);
    padding: 0.3rem 0.36rem;
    margin: 0.1rem;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

@media (min-width: 768px) {
    .menuBar button {
        padding: 0.3rem 0.42rem;
    }
}

.menuBar button:hover {
    background-color: #dadada;
    color: rgb(70, 70, 70);
}

.menuBar .divider {
    margin: 0.2rem;
    border-left: 1px solid rgba(13, 13, 13, 0.1);
    align-self: stretch;
}
